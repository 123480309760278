var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { align: "left" } },
            [
              _c(
                "b-button-group",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapse-1",
                      arg: "collapse-1",
                    },
                  ],
                  staticClass: "my-2 filter-button-group",
                  attrs: { title: _vm.getDefaultFilterMessage() },
                },
                [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v("\n        Filtra\n      "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          attrs: { id: "collapse-1" },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.loadData(1)
                },
              },
            },
            [
              _c(
                "b-card",
                { staticClass: "filter" },
                [
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "col-md-4" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "code",
                            type: "text",
                            label: "Compagnia",
                            options: _vm.insurers,
                          },
                          on: { input: _vm.onInputInsurer },
                          model: {
                            value: _vm.filter.byInsurer.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byInsurer, "id", $$v)
                            },
                            expression: "filter.byInsurer.id",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-3 align-self-end" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle:collapse-1",
                                arg: "collapse-1",
                              },
                            ],
                            attrs: {
                              type: "submit",
                              variant: "lisaweb",
                              size: "sm",
                              disabled: !_vm.filter.byInsurer.id,
                            },
                          },
                          [_vm._v("Cerca")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-reset",
                            attrs: {
                              type: "button",
                              variant: "lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClearFilter()
                              },
                            },
                          },
                          [_vm._v("Reset")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c("div", [
                      _vm.showForm && _vm.visibleStates[0] && _vm.insurerHasFee
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "mb-2",
                                  attrs: { header: "Diritti" },
                                },
                                [
                                  _c(
                                    "b-card-text",
                                    [
                                      _c("b-row", [
                                        _c(
                                          "div",
                                          { staticClass: "col-md-3" },
                                          [
                                            _c("base-currency", {
                                              attrs: {
                                                name: "fee",
                                                vid: "fee",
                                                label: "Percentuale",
                                                placeholder: "Percentuale",
                                                options: {
                                                  currencyDisplay: "percent",
                                                  locale: "it-IT",
                                                  precision: 2,
                                                },
                                              },
                                              model: {
                                                value: _vm.fee,
                                                callback: function ($$v) {
                                                  _vm.fee = $$v
                                                },
                                                expression: "fee",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-group col-md-3 align-self-end",
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "btn-create",
                                                attrs: {
                                                  type: "button",
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                  disabled: _vm.onSavingFee,
                                                },
                                                on: { click: _vm.onSaveFee },
                                              },
                                              [
                                                _c("b-spinner", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.onSavingFee,
                                                      expression: "onSavingFee",
                                                    },
                                                  ],
                                                  attrs: {
                                                    small: "",
                                                    type: "grow",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                    Salva Percentuale"
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showForm
                        ? _c(
                            "div",
                            [
                              _c("validation-observer", {
                                ref: "observer",
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ invalid }) {
                                        return [
                                          _c(
                                            "b-form",
                                            {
                                              on: {
                                                submit: function ($event) {
                                                  $event.preventDefault()
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "b-card",
                                                {
                                                  attrs: {
                                                    header:
                                                      "Produttore: " +
                                                      _vm.getStringValue(
                                                        "salesmen",
                                                        _vm.salesman_id
                                                      ),
                                                    "header-tag": "header",
                                                  },
                                                },
                                                [
                                                  _c("b-card-text", [
                                                    _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "b-collapse",
                                                          {
                                                            attrs: {
                                                              id: "accordion-1",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .visibleStates[0],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.visibleStates,
                                                                    0,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "visibleStates[0]",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "my-2",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "level-title",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      ":: Rami ::"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  "\n                        Compagnia:\n                        " +
                                                                    _vm._s(
                                                                      _vm.getStringValue(
                                                                        "insurers",
                                                                        _vm
                                                                          .filter
                                                                          .byInsurer
                                                                          .id,
                                                                        "code"
                                                                      ) +
                                                                        " - " +
                                                                        _vm.getStringValue(
                                                                          "insurers",
                                                                          _vm
                                                                            .filter
                                                                            .byInsurer
                                                                            .id,
                                                                          "title"
                                                                        )
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      variant:
                                                                        "lisaweb",
                                                                      disabled:
                                                                        !_vm
                                                                          .form[
                                                                          "level1"
                                                                        ].pdata
                                                                          .length &&
                                                                        !_vm
                                                                          .form[
                                                                          "level1"
                                                                        ].npdata
                                                                          .length,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        _vm.onCopy,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "copia capitolato"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("table", [
                                                              _c("thead", [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "button",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Azione"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "button",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Gestisci Rischi"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.showID
                                                                  ? _c("th", [
                                                                      _vm._v(
                                                                        "ID"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Codice Ramo"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Descrizione"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Acquisto (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Incasso (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "tbody",
                                                                [
                                                                  !_vm.form[
                                                                    "level1"
                                                                  ].pdata
                                                                    .length &&
                                                                  !_vm.form[
                                                                    "level1"
                                                                  ].npdata
                                                                    .length
                                                                    ? _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                colspan:
                                                                                  "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Non ci sono rami associati"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level1"
                                                                    ].pdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "pdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "action-buttons",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          disabled:
                                                                                            invalid ||
                                                                                            _vm.isDisabled(
                                                                                              1,
                                                                                              "pdata",
                                                                                              index
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.updateData(
                                                                                              1,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "aggiorna"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          title:
                                                                                            "elimina",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteData(
                                                                                              1,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "trash",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "primary",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.loadData(
                                                                                          2,
                                                                                          data.id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "rischi"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.code
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level1_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level1_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].pdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level1_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level1_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].pdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level1_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level1_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].pdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level1_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level1_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].pdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level1"
                                                                    ].npdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "npdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "lisaweb",
                                                                                      disabled:
                                                                                        invalid ||
                                                                                        _vm.isDisabled(
                                                                                          1,
                                                                                          "npdata",
                                                                                          index
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.storeData(
                                                                                          1,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "salva"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "primary",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.loadData(
                                                                                          2,
                                                                                          data.id
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "rischi"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.code
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level1_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level1_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].npdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level1_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level1_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].npdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level1_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level1_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].npdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level1_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level1_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  1,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level1"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level1"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level1'].npdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "py-2 mb-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                      disabled:
                                                                        invalid ||
                                                                        _vm.areAllDisabled(
                                                                          1
                                                                        ),
                                                                      variant:
                                                                        "lisaweb",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveAll(
                                                                            1
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          Salva Tutti\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-collapse",
                                                          {
                                                            attrs: {
                                                              id: "accordion-2",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .visibleStates[1],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.visibleStates,
                                                                    1,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "visibleStates[1]",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "left",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mb-1",
                                                                        attrs: {
                                                                          variant:
                                                                            "light",
                                                                          title:
                                                                            "livello superiore",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.collapse(
                                                                                2
                                                                              )
                                                                              _vm.expand(
                                                                                1
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "backspace",
                                                                                "aria-hidden":
                                                                                  "true",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "level-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          ":: Rischi ::"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                          Compagnia:\n                          " +
                                                                        _vm._s(
                                                                          _vm.getStringValue(
                                                                            "insurers",
                                                                            _vm
                                                                              .filter
                                                                              .byInsurer
                                                                              .id,
                                                                            "code"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.getStringValue(
                                                                              "insurers",
                                                                              _vm
                                                                                .filter
                                                                                .byInsurer
                                                                                .id,
                                                                              "title"
                                                                            )
                                                                        ) +
                                                                        "\n                          :: Ramo:\n                          " +
                                                                        _vm._s(
                                                                          _vm.getStringValue(
                                                                            "branches",
                                                                            _vm
                                                                              .filter
                                                                              .byRiskBranch
                                                                              .id,
                                                                            "code"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.getStringValue(
                                                                              "branches",
                                                                              _vm
                                                                                .filter
                                                                                .byRiskBranch
                                                                                .id,
                                                                              "title"
                                                                            )
                                                                        )
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("table", [
                                                              _c("thead", [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "button",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Azione"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "button",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Gestisci Scorpori"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.showID
                                                                  ? _c("th", [
                                                                      _vm._v(
                                                                        "ID"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Codice Rischio"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Descrizione"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Acquisto (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Incasso (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "tbody",
                                                                [
                                                                  !_vm.form[
                                                                    "level2"
                                                                  ].pdata
                                                                    .length &&
                                                                  !_vm.form[
                                                                    "level2"
                                                                  ].npdata
                                                                    .length
                                                                    ? _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                colspan:
                                                                                  "6",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Non ci sono prodotti associati\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level2"
                                                                    ].pdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "pdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "action-buttons",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          disabled:
                                                                                            invalid ||
                                                                                            _vm.isDisabled(
                                                                                              2,
                                                                                              "pdata",
                                                                                              index
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.filter.byInsuranceRisk.id =
                                                                                              data.id
                                                                                            _vm.updateData(
                                                                                              2,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "aggiorna"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          title:
                                                                                            "elimina",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteData(
                                                                                              2,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "trash",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td"
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.code
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level2_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level2_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].pdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level2_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level2_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].pdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level2_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level2_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].pdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level2_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level2_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].pdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level2"
                                                                    ].npdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "npdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "lisaweb",
                                                                                      disabled:
                                                                                        invalid ||
                                                                                        _vm.isDisabled(
                                                                                          2,
                                                                                          "npdata",
                                                                                          index
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.filter.byInsuranceRisk.id =
                                                                                          data.id
                                                                                        _vm.storeData(
                                                                                          2,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "salva"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td"
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.code
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                              "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level2_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level2_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].npdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level2_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level2_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].npdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level2_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level2_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].npdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level2_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level2_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  2,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level2"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level2"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level2'].npdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "py-2 mb-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                      disabled:
                                                                        invalid ||
                                                                        _vm.areAllDisabled(
                                                                          2
                                                                        ),
                                                                      variant:
                                                                        "lisaweb",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveAll(
                                                                            2
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          Salva Tutti\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-collapse",
                                                          {
                                                            attrs: {
                                                              id: "accordion-3",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .visibleStates[2],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.visibleStates,
                                                                    2,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "visibleStates[2]",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-row",
                                                              [
                                                                _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      align:
                                                                        "left",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-button",
                                                                      {
                                                                        staticClass:
                                                                          "mb-1",
                                                                        attrs: {
                                                                          variant:
                                                                            "light",
                                                                          title:
                                                                            "livello superiore",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.collapse(
                                                                                3
                                                                              )
                                                                              _vm.expand(
                                                                                2
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-icon",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                icon: "backspace",
                                                                                "aria-hidden":
                                                                                  "true",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "level-title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          ":: Scorpori ::"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                          Compagnia:\n                          " +
                                                                        _vm._s(
                                                                          _vm.getStringValue(
                                                                            "insurers",
                                                                            _vm
                                                                              .filter
                                                                              .byInsurer
                                                                              .id,
                                                                            "code"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.getStringValue(
                                                                              "insurers",
                                                                              _vm
                                                                                .filter
                                                                                .byInsurer
                                                                                .id,
                                                                              "title"
                                                                            )
                                                                        ) +
                                                                        "\n                          :: Ramo:\n                          " +
                                                                        _vm._s(
                                                                          _vm.getStringValue(
                                                                            "branches",
                                                                            _vm
                                                                              .filter
                                                                              .byRiskBranch
                                                                              .id,
                                                                            "code"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.getStringValue(
                                                                              "branches",
                                                                              _vm
                                                                                .filter
                                                                                .byRiskBranch
                                                                                .id,
                                                                              "title"
                                                                            )
                                                                        ) +
                                                                        "\n                          :: Prodotto:\n                          " +
                                                                        _vm._s(
                                                                          _vm.getStringValue(
                                                                            "products",
                                                                            _vm
                                                                              .filter
                                                                              .byInsuranceRisk
                                                                              .id,
                                                                            "code"
                                                                          ) +
                                                                            " - " +
                                                                            _vm.getStringValue(
                                                                              "products",
                                                                              _vm
                                                                                .filter
                                                                                .byInsuranceRisk
                                                                                .id,
                                                                              "title"
                                                                            )
                                                                        )
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c("table", [
                                                              _c("thead", [
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "button",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Azione"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm.showID
                                                                  ? _c("th", [
                                                                      _vm._v(
                                                                        "ID"
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                                _vm._v(" "),
                                                                _c("th", [
                                                                  _vm._v(
                                                                    "Descrizione garanzia"
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Acquisto (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "th",
                                                                  {
                                                                    staticClass:
                                                                      "pivot",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Calcolo Incasso (%)"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "tbody",
                                                                [
                                                                  !_vm.form[
                                                                    "level3"
                                                                  ].pdata
                                                                    .length &&
                                                                  !_vm.form[
                                                                    "level3"
                                                                  ].npdata
                                                                    .length
                                                                    ? _c("tr", [
                                                                        _c(
                                                                          "td",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                colspan:
                                                                                  "4",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              Non ci sono scorpori associati\n                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ])
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level3"
                                                                    ].pdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "pdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "action-buttons",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          disabled:
                                                                                            invalid ||
                                                                                            _vm.isDisabled(
                                                                                              3,
                                                                                              "pdata",
                                                                                              index
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            _vm.filter.byBreakdown.id =
                                                                                              data.id
                                                                                            _vm.updateData(
                                                                                              3,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "aggiorna"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "b-button",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2",
                                                                                      attrs:
                                                                                        {
                                                                                          variant:
                                                                                            "lisaweb",
                                                                                          title:
                                                                                            "elimina",
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.deleteData(
                                                                                              3,
                                                                                              index
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-icon",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              icon: "trash",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level3_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level3_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].pdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level3_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level3_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].pdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level3_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level3_pdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].pdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level3_pdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level3_pdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "pdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .pdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .pdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].pdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _vm._l(
                                                                    _vm.form[
                                                                      "level3"
                                                                    ].npdata,
                                                                    function (
                                                                      data,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "tr",
                                                                        {
                                                                          key:
                                                                            "npdata" +
                                                                            index,
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-button",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      variant:
                                                                                        "lisaweb",
                                                                                      disabled:
                                                                                        invalid ||
                                                                                        _vm.isDisabled(
                                                                                          3,
                                                                                          "npdata",
                                                                                          index
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.filter.byBreakdown.id =
                                                                                          data.id
                                                                                        _vm.storeData(
                                                                                          3,
                                                                                          index
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "salva"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _vm.showID
                                                                            ? _c(
                                                                                "td",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      data.id
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    data.title
                                                                                  ) +
                                                                                  "\n                            "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_commission_level3_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_commission_level3_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].npdata[index]\n                                        .purchase_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "purchase_calculation_type_level3_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "purchase_calculation_type_level3_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.purchase_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .purchase_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "purchase_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].npdata[index]\n                                        .purchase_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "td",
                                                                            [
                                                                              _c(
                                                                                "b-row",
                                                                                [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-currency",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_commission_level3_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_commission_level3_npdata_" +
                                                                                                index,
                                                                                              type: "text",
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                {
                                                                                                  locale:
                                                                                                    "it-IT",
                                                                                                  currencyDisplay:
                                                                                                    "percent",
                                                                                                  precision: 2,
                                                                                                },
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_commission"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            keydown:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_commission,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_commission",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].npdata[index]\n                                        .take_commission\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "col-md-8",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "base-radio",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              name:
                                                                                                "take_calculation_type_level3_npdata_" +
                                                                                                index,
                                                                                              vid:
                                                                                                "take_calculation_type_level3_npdata_" +
                                                                                                index,
                                                                                              label:
                                                                                                "",
                                                                                              options:
                                                                                                _vm.calculation_types,
                                                                                              rules:
                                                                                                _vm.getRules(
                                                                                                  "**.take_calculation_type"
                                                                                                ),
                                                                                            },
                                                                                          on: {
                                                                                            input:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.enableButton(
                                                                                                  3,
                                                                                                  "npdata",
                                                                                                  index,
                                                                                                  ...arguments
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                          model:
                                                                                            {
                                                                                              value:
                                                                                                _vm
                                                                                                  .form[
                                                                                                  "level3"
                                                                                                ]
                                                                                                  .npdata[
                                                                                                  index
                                                                                                ]
                                                                                                  .take_calculation_type,
                                                                                              callback:
                                                                                                function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    _vm
                                                                                                      .form[
                                                                                                      "level3"
                                                                                                    ]
                                                                                                      .npdata[
                                                                                                      index
                                                                                                    ],
                                                                                                    "take_calculation_type",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                              expression:
                                                                                                "\n                                      form['level3'].npdata[index]\n                                        .take_calculation_type\n                                    ",
                                                                                            },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                ],
                                                                2
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "py-2 mb-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    attrs: {
                                                                      type: "button",
                                                                      disabled:
                                                                        invalid ||
                                                                        _vm.areAllDisabled(
                                                                          3
                                                                        ),
                                                                      variant:
                                                                        "lisaweb",
                                                                      size: "sm",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveAll(
                                                                            3
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          Salva Tutti\n                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2591639596
                                ),
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "my-2" },
                            [
                              _c(
                                "b-card",
                                {
                                  attrs: {
                                    header:
                                      "Produttore: " +
                                      _vm.getStringValue(
                                        "salesmen",
                                        _vm.salesman_id
                                      ),
                                    "header-tag": "header",
                                  },
                                },
                                [
                                  _c("b-card-text", [
                                    _c("div", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.message2) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ])
                  : _c("div", [
                      _vm._v("\n        " + _vm._s(_vm.message1) + "\n      "),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("copy-commissions-modal", {
            attrs: {
              opts: _vm.brokersOptions,
              title: "Copia Capitolato",
              text: `Per procedere è necessario selezionare uno o più produttori:
    <br />
    Compagnia:<b>${_vm.getStringValue(
      "insurers",
      _vm.filter.byInsurer.id,
      "code"
    )} -
      ${_vm.getStringValue("insurers", _vm.filter.byInsurer.id, "title")}</b>
    <br />
    Produttore:<b>
      ${_vm.getStringValue("salesmen", _vm.salesman_id)}
    </b>`,
            },
            on: { copy: _vm.copy },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }