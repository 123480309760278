<template>
  <div class="mt-1 mx-3">
    <salesmen-commissions> </salesmen-commissions>
  </div>
</template>

<script>
import SalesmenCommissions from "@/components/profile/brokers/salesmen/commissions.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
    ...mapActions("breadcrumbs", { removeBCS: "remove" }),
    ...mapGetters("breadcrumbs", { loadBCS: "loadBreadcrumbs" }),
  },
  components: {
    SalesmenCommissions,
  },
  beforeMount() {
    let bcs = this.loadBCS();
    if (this.$route.query.fromShortcut) {
      bcs.push({
        text: "Capitolato Produttore",
        path: "salesmen.pivot",
        level: 4,
      });
      bcs.forEach((element) => {
        this.notifyQueue({
          text: element.text,
          path: element.path,
          tab: element.tab,
          level: element.level,
          disabled: element.disabled,
        });
      });
      // this.$router.replace({ query: null });
    } else {
      this.notifyQueue({
        text: "Capitolato Produttore",
        path: "salesmen.pivot",
        level: 4,
      });
    }
  },
};
</script>
